import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import moment from "moment";
import reportService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import VueJsonToCsv from "vue-json-to-csv";
import html2pdf from "html2pdf.js";
import shippingService from "../../shipping/js/shipping-service";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      showStartDate: true,
      showEndDate: true,
      startDate: false,
      endDate: false,
      dateSelected: false,
      selectedProject: "",
      projectData: [],
      selectedShipper: "",
      shipperData: [],
      selectedAuditor: "",
      auditorData: [],
      runDisabled: false,
      showData: false,
      totalRecords: 0,
      fulfillmentAuditData: [],
      export50kData: false,
      exportDialog: false,
      csvName: "",
      fulfillmentAuditDetails: [],
      pdfName: "",
      showAuditBoxFailHist: false,
      auditFailHistory: [],
      jsonSNFail: [],
      jsonAnsFail: [],
      headerFulfillmentAudit: [
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Order", value: "Order", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Audit ID", value: "AuditID", class: "primary customwhite--text" },
        { text: "Shipper", value: "Shipper", class: "primary customwhite--text" },
        { text: "Shipped Date", value: "Shipdate", class: "primary customwhite--text" },
        { text: "Auditor", value: "Auditor", class: "primary customwhite--text" },
        { text: "Audit Completed", value: "Completed", class: "primary customwhite--text" },
        { text: "Completion Date", value: "Audit_Complete_Date", class: "primary customwhite--text" },
        { text: "Result", value: "Result", class: "primary customwhite--text" },
        { text: "# of Re-Audits", value: "No_Of_ReAudits", class: "primary customwhite--text" },
        { text: "Audit Fail History", value: "actions", class: "primary customwhite--text" },
      ],
      json_fields: {
        Project: "Project",
        Order: "Order",
        RMA: "RMA",
        "Audit ID": "AuditID",
        Shipper: "Shipper",
        "Shipped Date": "Shipdate",
        Auditor: "Auditor",
        "Audit Completed": "Completed",
        "Completion Date": "Audit_Complete_Date",
        Result: "Result",
        "# of Re-Audits": "No_Of_ReAudits",
      },
      labels_json: {
        Project: { title: "Project" },
        Order: { title: "Order" },
        RMA: { title: "RMA" },
        AuditID: { title: "Audit ID" },
        Shipper: { title: "Shipper" },
        Shipdate: { title: "Shipped Date" },
        Auditor: { title: "Auditor" },
        Completed: { title: "Audit Completed" },
        Audit_Complete_Date: { title: "Completion Date" },
        Result: { title: "Result" },
        No_Of_ReAudits: { title: "# of Re-Audits" },
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getFulfillmentDropData();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.startDate = false;
      this.endDate = false;
      this.dateSelected = false;
      this.selectedProject = "";
      this.projectData = [];
      this.selectedShipper = "";
      this.shipperData = [];
      this.selectedAuditor = "";
      this.auditorData = [];
      this.runDisabled = false;
      this.showData = false;
      this.totalRecords = 0;
      this.fulfillmentAuditData = [];
      this.export50kData = false;
      this.exportDialog = false;
      this.csvName = "";
      this.fulfillmentAuditDetails = [];
      this.pdfName = "";
      this.showAuditBoxFailHist = false;
      this.auditFailHistory = [];
      this.jsonSNFail = [];
      this.jsonAnsFail = [];
      this.getFulfillmentDropData();
    },
    // set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //On click continue
    onContinue() {
      !this.sDate ? (this.sDate = new Date(new Date().getTime() - 86400000).toISOString().substr(0, 10)) : "";
      this.dateSelected = true;
      this.runDisabled = true;
    },
    //Get Dropdown data
    async getFulfillmentDropData() {
      let projectData = await reportService.getFulfillmentProjDrop("get", this.userId);
      if (projectData !== undefined) {
        if (projectData.message !== "NA") {
          this.projectData = projectData;
        }
      }
      let shipperData = await reportService.getFulfillmentShipperDrop("get", this.userId);
      if (shipperData !== undefined) {
        if (shipperData.message !== "NA") {
          this.shipperData = shipperData;
        }
      }
      let auditorData = await reportService.getFulfillmentAuditorDrop("get", this.userId);
      if (auditorData !== undefined) {
        if (auditorData.message !== "NA") {
          this.auditorData = auditorData;
        }
      }
    },
    //Run Report
    async runReport() {
      if (this.selectedProject !== "") {
        if (this.selectedShipper !== "") {
          if (this.selectedAuditor !== "") {
            let obj = {
              userid: parseInt(this.userId),
              lcsdate: Utility.convertLocalToUTC(this.sDate),
              lcedate: Utility.convertLocalToUTC(this.eDate),
              proj_key: parseInt(this.selectedProject),
              ship_user_key: parseInt(this.selectedShipper),
              audit_user_key: parseInt(this.selectedAuditor),
            };
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.axios
              .post("/rt/fulfillment_audit_report_details", obj)
              .then((response) => {
                if (response.status == 200) {
                  let responseData = response.data.body;
                  if (responseData?.length !== 0) {
                    const newArr = responseData.map((obj) => {
                      return {
                        ...obj,
                        Shipdate: obj.Shipdate !== "" ? Utility.convertESTToLocal(obj.Shipdate) : "",
                        Audit_Complete_Date: obj.Audit_Complete_Date !== "" ? Utility.convertESTToLocal(obj.Audit_Complete_Date) : "",
                      };
                    });
                    this.fulfillmentAuditData = newArr;
                    this.totalRecords = responseData.length;
                    this.showData = true;
                  } else {
                    this.receiptData = [];
                    this.totalRecords = 0;
                    this.showData = false;
                    let Alert = {
                      type: "error",
                      isShow: true,
                      message: "No records based on the selected criteria",
                    };
                    this.$store.commit("ConfigModule/Alert", Alert);
                  }
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  var Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                var Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
              });
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Please select a Auditor",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select a Shipper",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select a Project",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    async exportToExcel() {
      if (this.totalRecords <= 15000) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Fulfillment Audit Report");
        const borderStyle = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
        const colors = {
          grey: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        //Add Header Rows
        worksheet.addRow([
          "Project",
          "Order",
          "RMA",
          "Audit ID",
          "Shipper",
          "Shipped Date",
          "Auditor",
          "Audit Completed",
          "Completion Date",
          "Result",
          "# of Re-Audits",
        ]);
        // Style configuration
        const headerStyle = {
          font: { bold: true },
          alignment: { horizontal: "center", vertical: "middle" },
          border: {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          },
          fill: { type: "pattern", pattern: "solid", fgColor: { argb: "D3D3D3" } },
        };
        // Apply styles to the header rows
        worksheet.getRow(1).eachCell((cell) => {
          cell.style = { ...headerStyle, fill: colors.grey };
          cell.border = borderStyle;
        });
        let obj = {
          userid: parseInt(this.userId),
          lcsdate: Utility.convertLocalToUTC(this.sDate),
          lcedate: Utility.convertLocalToUTC(this.eDate),
          proj_key: parseInt(this.selectedProject),
          ship_user_key: parseInt(this.selectedShipper),
          audit_user_key: parseInt(this.selectedAuditor),
        };
        const response = await this.axios.post("/rt/fulfillment_audit_report_details", obj);
        let responseData = response.data.body;
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Shipdate: obj.Shipdate !== "" ? Utility.convertESTToLocal(obj.Shipdate) : "",
            Audit_Complete_Date: obj.Audit_Complete_Date !== "" ? Utility.convertESTToLocal(obj.Audit_Complete_Date) : "",
          };
        });
        this.export50kData = false;
        //Add your data rows
        newArr.forEach((item) => {
          const row = worksheet.addRow([
            item.Project,
            item.Order,
            item.RMA,
            item.AuditID,
            item.Shipper,
            item.Shipdate,
            item.Auditor,
            item.Completed,
            item.Audit_Complete_Date,
            item.Result,
            item.No_Of_ReAudits,
          ]);
          row.eachCell({ includeEmpty: true }, (cell) => {
            //Apply borders
            cell.border = borderStyle;
          });
        });
        //Download the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), Utility.excelFileName("Fulfillment_Audit_Report"));
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //CSV Export functionality
    csvFileName() {
      this.csvName = "Fulfillment_Audit_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_");
    },
    //Export pdf
    async printDetails() {
      let obj = {
        userid: parseInt(this.userId),
        lcsdate: Utility.convertLocalToUTC(this.sDate),
        lcedate: Utility.convertLocalToUTC(this.eDate),
        proj_key: parseInt(this.selectedProject),
        ship_user_key: parseInt(this.selectedShipper),
        audit_user_key: parseInt(this.selectedAuditor),
      };
      const response = await this.axios.post("/rt/fulfillment_audit_report_details", obj);
      let responseData = response.data.body;
      const newArr = responseData.map((obj) => {
        return {
          ...obj,
          Shipdate: obj.Shipdate !== "" ? Utility.convertESTToLocal(obj.Shipdate) : "",
          Audit_Complete_Date: obj.Audit_Complete_Date !== "" ? Utility.convertESTToLocal(obj.Audit_Complete_Date) : "",
        };
      });
      for (let i = 0; i < newArr.length; i++) {
        delete newArr[i].audit_box_key;
      }
      this.fulfillmentAuditDetails = [];
      this.fulfillmentAuditDetails = this.buildHtmlTable(newArr);
      this.pdfName =
        "Fulfillment_Audit_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.fulfillmentAuditDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "a0", orientation: "landscape" },
      });
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          try {
            if (typeof cellValue === "boolean") {
              td.appendChild(document.createTextNode(cellValue || ""));
              tr.appendChild(td);
            } else {
              new Date(cellValue);
              td.appendChild(document.createTextNode(Utility.convertESTToLocal(cellValue) || ""));
              tr.appendChild(td);
            }
          } catch (e) {
            td.appendChild(document.createTextNode(cellValue || ""));
            tr.appendChild(td);
          }
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "Project") key = "Project";
              if (key == "Order") key = "Order";
              if (key == "RMA") key = "RMA";
              if (key == "AuditID") key = "Audit ID";
              if (key == "Shipper") key = "Shipper";
              if (key == "Shipdate") key = "Shipped Date";
              if (key == "Auditor") key = "Auditor";
              if (key == "Completed") key = "Audit Completed";
              if (key == "Audit_Complete_Date") key = "Completion Date";
              if (key == "Result") key = "Result";
              if (key == "No_Of_ReAudits") key = "# of Re-Audits";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Get Audit Box Fail History
    async getAuditBoxFailHistory(item) {
      let response = await shippingService.getAuditBoxFailHistory("get", this.userId, 0, parseInt(item.audit_box_key));
      if (response.message !== "NA" && response !== 400) {
        this.showAuditBoxFailHist = true;
        this.auditFailHistory = [];
        this.auditFailHistory = response;
        if (this.auditFailHistory[0]?.AnsJSON_FAIL !== "" || this.auditFailHistory[0]?.SN_JSON_FAIL !== "") {
          if (this.auditFailHistory[0].AnsJSON_FAIL !== "" && this.auditFailHistory[0].AnsJSON_FAIL !== undefined) {
            this.jsonAnsFail = JSON.parse(this.auditFailHistory[0]?.AnsJSON_FAIL);
          }
          if (this.auditFailHistory[0].SN_JSON_FAIL !== "" && this.auditFailHistory[0].SN_JSON_FAIL !== undefined) {
            this.jsonSNFail = JSON.parse(this.auditFailHistory[0]?.SN_JSON_FAIL);
          }
        } else {
          this.showAuditBoxFailHist = false;
          this.auditFailHistory = [];
          this.jsonSNFail = [];
          this.jsonAnsFail = [];
          let Alert = {
            type: "error",
            isShow: true,
            message: "Fail history not available",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        this.showAuditBoxFailHist = false;
        this.auditFailHistory = [];
        this.jsonSNFail = [];
        this.jsonAnsFail = [];
        let Alert = {
          type: "error",
          isShow: true,
          message: "Fail history not available",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To brings page based actions to the page
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
    VueJsonToCsv,
    html2pdf,
  },
};
